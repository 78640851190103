<template>
    <div class="not-found">
        <h1>404</h1>
        <p>nothing to find here.</p>
    </div>
</template>

<script>
export default {
name: 'NotFoundPage',
};
</script>

<style scoped>
    .not-found {
        text-align: center;
        margin-top: 50px;
    }
</style>